import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import {
  darkBlue,
  InnerWrapper,
  StyledEmail,
  PageTitle,
  lightBlue,
  mainWhite,
  Subtitle,
  screen,
} from "../components/common/variables";
import { FaChevronRight } from "react-icons/fa";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

const Wrapper = styled.div`
  padding-top: 0;
  background-color: ${mainWhite};
  @media ${screen.medium} {
    padding-top: 70px;
  }

  .large-banner {
    position: relative;
    display: none;
    @media ${screen.small} {
      display: block;
    }

    &__texts-container {
      position: absolute;
      top: 50%;
      z-index: 2;
      left: 50px;
      transform: translateY(-50%);
      @media ${screen.medium} {
        left: 80px;
      }
      @media ${screen.xLarge} {
        left: 140px;
      }
      h1 {
        color: ${mainWhite};
        line-height: 1.1;
        @media ${screen.xSmall} {
          right: 6%;
        }
        @media ${screen.xLarge} {
          right: 170px;
        }
        @media ${screen.xXLarge} {
          right: 10%;
        }
        .thin-white-1,
        .thin-white-2 {
          font-style: italic;
          font-size: 3.3rem;
          font-weight: 300;
          display: none;
          @media ${screen.small} {
            display: inline;
            font-size: 1.9rem;
          }
          @media ${screen.large} {
            font-size: 3.1rem;
          }
          @media ${screen.xLarge} {
            font-size: 3.2rem;
          }
          @media ${screen.xXLarge} {
            font-size: 3.8rem;
          }
        }
        .big {
          color: ${darkBlue};
          font-size: 7.2rem;
          font-weight: 900;
          margin-left: 20px;
          @media ${screen.xSmall} {
            font-size: 4.4rem;
          }
          @media ${screen.large} {
            font-size: 6.5rem;
          }
          @media ${screen.xLarge} {
            font-size: 7.1rem;
          }
          @media ${screen.xXLarge} {
            font-size: 8.2rem;
          }
        }
        .bold-white {
          font-weight: 900;
        }
        .thin-white-2 {
          margin-left: 55px;
        }
      }

      p {
        color: ${mainWhite};
        display: inline-block;
        margin-top: 30px;
        margin-left: 80px;
        font-size: 1.1rem;
        @media ${screen.small} {
          font-size: 1.1rem;
        }
        @media ${screen.large} {
          font-size: 1.2rem;
        }
        @media ${screen.xLarge} {
          font-size: 1.3rem;
        }
        @media ${screen.xXLarge} {
          font-size: 1.4rem;
        }
      }
      a {
        background: ${mainWhite};
        color: ${lightBlue};
        display: inline-block;
        margin-left: 20px;
      }
    }
  }

  .small-banner {
    display: block;
    @media ${screen.small} {
      display: none;
    }
  }

  .title-mobile {
    display: block;
    margin-bottom: 10px;
    font-weight: 900;
    @media ${screen.small} {
      display: none;
    }
  }

  .contact-btn-mobile {
    display: inline-block;
    width: 100%;
    text-align: center;
    @media ${screen.small} {
      display: none;
    }
  }

  .inner-wrapper {
    margin-top: 30px;
    @media ${screen.medium} {
      margin-top: 90px;
    }
  }

  .main-description {
    max-width: 674px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${darkBlue};
      margin-top: 45px;
      margin-bottom: 5px;
      font-size: 1.6rem;
      font-weight: 900;
      @media ${screen.small} {
        margin-top: 0;
        margin-bottom: 20px;
      }
      @media ${screen.medium} {
        font-size: 2.2rem;
      }
      @media ${screen.large} {
        font-size: 2.6rem;
      }
    }
    p {
      color: ${darkBlue};
      font-size: 1.18rem;
      margin-bottom: 14px;
      @media ${screen.medium} {
        font-size: 1.3rem;
        margin-bottom: 36px;
      }
    }
  }

  .secondary-description {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    @media ${screen.small} {
      justify-content: space-between;
      flex-direction: row;
    }
    @media ${screen.medium} {
      margin-top: 90px;
    }

    &__content {
      max-width: 674px;
      margin-left: 0;
      margin-top: 14px;
      @media ${screen.small} {
        margin-left: 0;
        margin-top: 0;
      }
      @media ${screen.medium} {
        margin-left: 35px;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${darkBlue};
        font-size: 3rem;
        font-weight: 900;
        margin-bottom: 20px;
      }
      p {
        color: ${darkBlue};
        font-size: 1rem;
        margin-bottom: 10px;
        @media ${screen.large} {
          font-size: 1.1rem;
          margin-bottom: 20px;
        }
      }
      ul {
        margin-top: 0px;
        margin-left: 20px;
        margin-bottom: 20px;
        @media ${screen.large} {
          margin-left: 40px;
          margin-top: -15px;
        }
        li {
          list-style-type: disc;
          color: ${darkBlue};
          font-size: 1rem;
          line-height: 1.4;
          margin-bottom: 4px;
          @media ${screen.large} {
            font-size: 1.1rem;
          }
        }
      }
    }

    &__img {
      max-width: 332px;
      margin: 0 auto;
      width: 100%;
      @media ${screen.small} {
        display: none;
      }
      @media ${screen.medium} {
        max-width: 462px;
        display: block;
      }
    }

    &__book-download {
      margin-top: 30px;
      margin-left: 0;
      @media ${screen.small} {
        margin-top: 40px;
        margin-left: 0;
      }
      @media ${screen.medium} {
        margin-top: 50px;
        margin-left: 35px;
      }
      h3 {
        display: block;
        font-size: 1.5rem;
        color: ${darkBlue};
        margin-bottom: 10px;
        @media ${screen.xSmall} {
          display: inline-block;
          margin-bottom: 0;
        }
      }
      .schedule {
        display: inline-block;
        margin-left: 0;
        width: 100%;
        text-align: center;
        @media ${screen.xSmall} {
          width: auto;
          margin-left: 30px;
        }
      }
      .download-links {
        margin-top: 20px;
        @media ${screen.medium} {
          margin-top: 40px;
        }
        a {
          color: ${lightBlue};
          display: block;
          font-size: 1.1rem;
          margin-bottom: 10px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .clients {
    margin-top: 35px;
    padding-bottom: 0;
    @media ${screen.small} {
      margin-top: 120px;
      padding-bottom: 50px;
    }
    .subtitle {
      text-align: left;
      line-height: 1.3;
      @media ${screen.small} {
        text-align: center;
      }
    }

    &__list {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      justify-content: space-around;
      margin-top: 30px;
      padding-bottom: 25px;
      @media ${screen.xSmall} {
        justify-content: space-evenly;
      }
      @media ${screen.medium} {
        /* justify-content: space-between; */
      }
      @media ${screen.large} {
        margin-top: 90px;
        padding-bottom: 20px;
      }
      @media ${screen.xLarge} {
        padding-bottom: 0;
      }

      li {
        width: 145px;
        margin-bottom: 25px;
        @media ${screen.xSmall} {
          margin-bottom: 35px;
          width: 270px;
        }
        @media ${screen.xLarge} {
          margin-bottom: 70px;
        }
      }

      &::after {
        max-width: 270px;
        width: 100%;
      }
    }
  }
`;
const TailoredAdvice = ({ data }) => {
  const titleTag = data.content.data.title_tag__tailored_advice_;
  const metaDescription = data.content.data.meta_description__tailored_advice_;
  const mainDescription = data.content.data.main_description2.html;
  const secondaryDescription = data.content.data.secondary_description1.html;
  const displayImg = data.content.data.display_image.gatsbyImageData;
  const clientList = data.content.data.clients_that_we_ve_advised;
  const email = data.details.data.email_address;
  const feeFile = data.details.data.consulting_fees.url;
  const bioFile = data.details.data.biography_file.url;
  return (
    <Layout>
      <SEO title={titleTag} description={metaDescription} />
      <Wrapper>
        <header className="large-banner">
          <div className="large-banner__texts-container">
            <h1>
              <span className="thin-white-1">LOOKING FOR</span>
              <br />
              <span className="big">ADVICE</span>
              <br />
              <span className="thin-white-2">
                FOR <span className="bold-white">YOUR BUSINESS?</span>
              </span>
            </h1>
            <p className="large-banner__contact">
              Book an initial consultation{" "}
            </p>
            <StyledEmail
              obfuscateChildren={false}
              email={email}
              headers={{
                subject: "I'd like to book an initial consultation with David",
              }}
            >
              SCHEDULE <FaChevronRight />
            </StyledEmail>
          </div>
          <StaticImage
            src="../images/global-adviser-alpha-tailored-advice.jpg"
            alt="Global Adviser Alpha tailored advice"
            placeholder="blurred"
          />
        </header>
        <header className="small-banner">
          <StaticImage
            src="../images/global-adviser-alpha-tailored-advice-for-your-business.jpg"
            alt="Global Adviser Alpha tailored advice"
            placeholder="blurred"
          />
        </header>
        <InnerWrapper className="inner-wrapper">
          <PageTitle className="title-mobile" dark>
            Looking for advice?
          </PageTitle>
          <StyledEmail
            className="contact-btn-mobile"
            obfuscateChildren={false}
            email={email}
            headers={{
              subject: "I'd like to book an initial consultation with David",
            }}
          >
            SCHEDULE A CONSULTATION <FaChevronRight />
          </StyledEmail>
          <main
            className="main-description"
            dangerouslySetInnerHTML={{ __html: mainDescription }}
          />

          <div className="secondary-description">
            <div className="secondary-description__img">
              <GatsbyImage
                image={displayImg}
                alt="Global Adviser Alpha vision and dreams"
              />
            </div>
            <div>
              <div
                className="secondary-description__content"
                dangerouslySetInnerHTML={{ __html: secondaryDescription }}
              />
              <div className="secondary-description__book-download">
                <h3>Book an initial consulation </h3>
                <StyledEmail
                  className="schedule"
                  obfuscateChildren={false}
                  email={email}
                  headers={{
                    subject:
                      "I'd like to book an initial consultation with David",
                  }}
                >
                  SCHEDULE <FaChevronRight />
                </StyledEmail>
                <div className="download-links">
                  <a
                    href={feeFile}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download the fee information
                  </a>
                  <a
                    href={bioFile}
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download David's full bio
                  </a>
                </div>
              </div>
            </div>
          </div>

          <section className="clients">
            <Subtitle dark className="subtitle">
              Clients that we've advised include
            </Subtitle>
            <ul className="clients__list">
              {clientList.map((client, i) => {
                return (
                  <li key={i}>
                    <GatsbyImage
                      image={client.image_logo.gatsbyImageData}
                      alt="Global Adviser Alpha clients"
                    />
                  </li>
                );
              })}
            </ul>
          </section>
        </InnerWrapper>
      </Wrapper>
    </Layout>
  );
};

export default TailoredAdvice;

export const data = graphql`
  {
    content: prismicServices {
      data {
        title_tag__tailored_advice_
        meta_description__tailored_advice_
        main_description2 {
          html
        }
        secondary_description1 {
          html
        }
        display_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        clients_that_we_ve_advised {
          image_logo {
            alt
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
    details: prismicGeneralDetails {
      data {
        email_address
        biography_file {
          url
        }
        consulting_fees {
          url
        }
      }
    }
  }
`;
